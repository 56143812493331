import LineChart from './LineChart/LineChart'
import Chart from 'chart.js'
import { ServiceFactory } from '@/services/ServiceFactory'
const _reportService = ServiceFactory.get('MenuReportService')
const _barsService = ServiceFactory.get('BarsService')
export default {
  name: 'App',
  components: {
    LineChart
  },
  data () {
    return {
      arrlabel: [],
      arrdata: [],
      arrlabels: [],
      arrIds: [],
      arrNames: [],
      arrcolor: [],
      arrcolorHex: [],
      activePicker: null,
      date: '',
      date1: '',
      menu: false,
      success: true,
      companies: [],
      companiesList: [],
      select: [],
      items: [],
      arrdata1: [],
      arrlabel1: [],
      contador: 1,
      datos: {}
    }
  },
  datasets: [{
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false
    }
  }
  ],
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },
  mounted () {
    this.reportSubmit()
    this.barList()
  },
  methods: {
    async barList () {
      this.companiesList = await _barsService.listBar()
    },
    async reportSubmit () {
      var ids = {
        barIds: this.select
      }
      // console.log(ids)
      // console.log(this.select)
      if (!this.select.length) {
        // console.log('Entro al if')
        let date = new Date()
        let dia = date.getDate()
        let mes = date.getMonth() + 1
        let anio = date.getFullYear()
        dia = ('0' + dia).slice(-2)
        mes = ('0' + mes).slice(-2)
        let formato = `${anio}-${mes}-${dia}`
        // console.log(formato)
        this.date = formato
        let cont = dia - 7
        let dia1 = date.getDate(date.setDate(cont))
        dia1 = ('0' + dia1).slice(-2)
        // console.log(dia1)
        if (mes <= 1) {
          let mes1 = date.getMonth(date.setMonth(mes - 1))
          mes1 = ('0' + mes1).slice(-2)
          // console.log(mes1)
          let anio1 = date.getFullYear(date.setFullYear(anio - 1))
          // console.log(anio1)
          let formato1 = `${anio1}-${mes1}-${dia1}`
          // console.log(formato1)
          this.date1 = formato1
        } else if (dia <= 6) {
          let mes1 = date.getMonth(date.setMonth(mes - 1))
          mes1 = ('0' + mes1).slice(-2)
          // console.log(mes1)
          let formato1 = `${anio}-${mes1}-${dia1}`
          // console.log(formato1)
          this.date1 = formato1
        } else {
          let formato1 = `${anio}-${mes}-${dia1}`
          // console.log(formato1)
          this.date1 = formato1
        }
        var payload = {
          dateStart: this.date,
          dateEnd: this.date1
        }
        // console.log(payload)
        try {
          this.companies = await _reportService.showDS(payload)
          // console.log(this.companies)
        } catch (error) {
          // console.info(error)
        }
        this.arrlabels = this.companies.labels
        // console.log(this.arrlabels)
        var c = 0
        Object.values(this.companies.datasets).forEach(element => {
          this.arrlabel[c] = element.label
          this.arrdata[c] = element.data
          this.arrcolorHex[c] = (Math.random() * 0xffffff * 1000000).toString(16)
          this.arrcolor[c] = `#${this.arrcolorHex[c].slice(0, 6)}`
          c++
        })
        // console.log(this.arrlabel)
        // console.log(this.arrdata)
        // console.log(this.arrcolor)
        // console.log(this.select)
        if (this.contador === 1) {
          // console.log('contador', this.contador)
          var d = 0
          Object.values(this.companies.businesses).forEach(element => {
            this.arrIds[d] = element.id
            this.arrNames[d] = element.name
            d++
          })
          // console.log(this.arrIds)
          // console.log(this.arrNames)
          this.select = this.arrIds
          this.items = this.arrNames
        }
        var array = []
        for (var i = 0; i < this.arrdata.length; i++) {
          array[i] = {
            label: [ this.arrlabel[i], ' visitas' ],
            borderColor: this.arrcolor[i],
            data: this.arrdata[i].reverse()
          }
        }
        this.datos = {
          labels: this.arrlabels.reverse(),
          datasets: array
        }
        // console.log(this.datos)
        if (this.contador !== 1) {
          this.datos = {
          }
        }
        this.contador = this.contador + 1
      } else {
        if (window.bar) {
          window.bar.clear()
          window.bar.destroy()
        }
        this.arrlabels = []
        this.arrlabel1 = []
        this.arrdata1 = []
        this.arrcolorHex = []
        this.arrcolor = []
        // console.log('Entro al else')
        var payload1 = {
          dateStart: this.date,
          dateEnd: this.date1,
          barIds: this.select
        }
        // console.log(this.select)
        // console.log(payload1)
        try {
          this.companies1 = await _reportService.showDSI(payload1)
          // console.log(this.companies1)
        } catch (error) {
          // console.info(error)
        }
        this.arrlabels = this.companies1.labels
        // console.log(this.arrlabels)
        var e = 0
        Object.values(this.companies1.datasets).forEach(elements => {
          this.arrlabel1[e] = elements.label
          this.arrdata1[e] = elements.data
          this.arrcolorHex[e] = (Math.random() * 0xffffff * 1000000).toString(16)
          this.arrcolor[e] = `#${this.arrcolorHex[e].slice(0, 6)}`
          e++
        })
        // console.log(this.arrlabel1)
        // console.log(this.arrdata1)
        // console.log(this.arrcolor)
        var array1 = []
        for (var j = 0; j < this.arrdata1.length; j++) {
          array1[j] = {
            label: [ this.arrlabel1[j], ' valor' ],
            borderColor: this.arrcolor[j],
            data: this.arrdata1[j].reverse()
          }
        }
        this.datos = {
          labels: this.arrlabels.reverse(),
          datasets: array1,
          backgroundColor: 'transparent'
        }
      }
      // console.log(this.datos)
      var canvas = this.$refs.canvas
      // console.log(canvas)
      canvas = document.getElementById('chart').getContext('2d')
      // console.log(canvas)
      window.bar = new Chart(canvas, {
        type: 'line',
        data: this.datos,
        options: {
          elements: {
            rectangle: {
              borderWidth: 1,
              borderSkipped: 'bottom'
            }
          },
          responsive: true,
          title: {
            display: true,
            text: 'Bar Report'
          },
          legend: { display: false }
        }
      })
    },
    save (date) {
      this.$refs.menu.save(date)
    }
  }
}
