<script>
export default {
  data () {
    return {
      activePicker: null,
      date1: null,
      menu1: false
    }
  },
  watch: {
    menu1 (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },
  methods: {
    save (date1) {
      this.$refs.menu1.save(date1)
    }
  }
}
</script>
